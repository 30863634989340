@font-face {
  font-family: 'Orkney';
  src: url('../assets/fonts/orkney-bold.woff');
  font-weight: bold;
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: 'Orkney';
  src: url('../assets/fonts/orkney-medium.woff');
  font-weight: medium;
  font-style: medium;
  font-weight: 500;
}
@font-face {
  font-family: 'Orkney';
  src: url('../assets/fonts/orkney-regular.woff');
  font-weight: normal;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Orkney';
  src: url('../assets/fonts/orkney-light.woff');
  font-style: light;
  font-weight: 100;
}

body {
  font-family: Orkney;
  @apply text-default-dark;
}

.react-pdf__Page__canvas,
.react-pdf__Page__svg,
.react-pdf__Page__svg svg {
  width: 100% !important;
  height: auto !important;
}

.link {
  @apply relative cursor-pointer inline-block;
}
.link:hover {
  @apply before:scale-x-100;
}
.link.link-default {
  @apply before:bg-default-pink text-default-pink;
}

.link.flash:hover {
  animation: flashAnimation 300ms;
  -moz-animation: flashAnimation 300ms;
  -webkit-animation: flashAnimation 300ms;
  -o-animation: flashAnimation 300ms;
}
.link.active {
  @apply before:scale-x-100;
}

.link::before {
  @apply content-[''] absolute block w-full h-[2px] lg:h-[.1vw] xl:h-[0.1vw]
  bottom-0 left-0 bg-default-dark
  scale-x-0 origin-top-left
  transition ease-in-out duration-300 rounded-lg lg:rounded-[0.3vw] leading-normal;
}

.nav {
  @apply border-b-[0.2vw] xxl:border-b-[0.2vw] border-b-default-pink transition-all leading-[0.8] border-opacity-0 duration-300 xl:leading-[0.8vw] font-bold;
}
.nav:hover {
  @apply border-opacity-100;
  animation: flashAnimation 300ms;
  -moz-animation: flashAnimation 300ms;
  -webkit-animation: flashAnimation 300ms;
  -o-animation: flashAnimation 300ms;
}
.nav.active {
  @apply border-opacity-100;
}

@keyframes flashAnimation {
  0% {
    @apply opacity-100;
  }
  50% {
    @apply opacity-70;
  }
  100% {
    @apply opacity-100;
  }
}

.btn {
  @apply p-3 cursor-pointer;
}

.btn-primary {
  @apply bg-default-pink text-default transition-all;
}
.btn-primary:hover {
  @apply bg-default-dark text-white;
}

.shake {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: translate(3px, 0);
  }
  50% {
    -moz-transform: translate(-3px, 0);
  }
  100% {
    -moz-transform: translate(0, 0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
  }
  50% {
    -webkit-transform: translate(-3px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform: translate(3px, 0);
  }
  50% {
    -ms-transform: translate(-3px, 0);
  }
  100% {
    -ms-transform: translate(0, 0);
  }
}

@-o-keyframes shake {
  0% {
    -o-transform: translate(3px, 0);
  }
  50% {
    -o-transform: translate(-3px, 0);
  }
  100% {
    -o-transform: translate(0, 0);
  }
}

.layout {
  @apply w-[70%] mx-auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
